<script setup lang="ts">
import { useAppConfiguration } from '~/stores/appConfiguration'
import { useConnectorAnalytics } from '~/stores/connectorAnalytics'
import { useWorkspace } from '~/stores/workspace'

const router = useRouter()

const navOpen = ref(false)

const appConfig = useAppConfiguration()
const { $auth, $auth0, $datadog } = useNuxtApp()
const { isAuthenticated, user, logout } = $auth0()
const connectorAnalytics = useConnectorAnalytics()
const workspace = useWorkspace()

const config = useRuntimeConfig()

const onSignOut = async () => {
  try {
    if (isAuthenticated.value)
      await logout({
        logoutParams: {
          returnTo: `${location.origin}/auth/login`,
        },
      })
    await $auth.tokenInvalidate()
    await connectorAnalytics.clearCacheDb()
  } catch (error) {
    $datadog.addError(error)
  }

  workspace.$reset()
  $datadog.clearUser()
  router.push('/signin')
}

const isMounted = useMounted()
const showWorkspaceSwitcher = ref(false)
const authInit = ref(!!$auth.profile.sub || !!user.value?.sub)

watch(
  [() => $auth.profile, user],
  ([profile, auth0User]) => {
    if (profile.sub || auth0User) {
      authInit.value = true
    }
  },
  {
    immediate: true,
  },
)

const version = computed(() =>
  /@rialtic.io$/.test($auth?.profile?.email)
    ? `v${config.public.APP_VERSION}`
    : '',
)
</script>

<template>
  <div>
    <ClientOnly>
      <Transition
        enter-active-class="transition-duration-600 transition-opacity"
        enter-from-class="opacity-0"
        leave-active-class="transition-duration-600 transition-opacity"
        leave-to-class="opacity-0"
      >
        <div v-if="authInit">
          <AppNavDrawer v-if="isMounted" v-model="navOpen" :version="version">
            <template #nav="{ isMini, toggleMini }">
              <NavMenuAccount
                :mini="isMini"
                :nav-open="navOpen"
                @nav-open="toggleMini(false)"
              />
            </template>
            <template #toolbar>
              <AppToolbar class="sticky top-0">
                <template #default>
                  <div
                    v-if="appConfig.maintenance"
                    class="bg-warning border-warning-dark absolute bottom-0 left-0 h-1 w-full"
                  />
                  <div class="flex h-full items-center">
                    <button
                      class="mr-2 h-8 w-8 rounded-full lg:hidden"
                      @click="navOpen = !navOpen"
                    >
                      <div class="i-ph-list h-6 w-6" />
                    </button>
                  </div>
                </template>
                <template #user-menu>
                  <div
                    v-if="appConfig.maintenance"
                    class="bg-warning border-warning-dark rounded px-2 py-1 text-xs"
                  >
                    Maintenance mode
                  </div>
                  <AppUserMenu
                    :logo-url="workspace.activeWorkspace?.logo || ''"
                  >
                    <ul class="flex flex-col p-2">
                      <li v-if="workspace.hasMany">
                        <button
                          class="user-menu-link"
                          @click="showWorkspaceSwitcher = true"
                        >
                          Switch Workspace
                        </button>
                      </li>
                      <li>
                        <button class="user-menu-link" @click="onSignOut()">
                          Log out
                        </button>
                      </li>
                    </ul>
                  </AppUserMenu>
                </template>
              </AppToolbar>
            </template>

            <div class="flex flex-1 flex-col">
              <slot />
            </div>
          </AppNavDrawer>
          <UiDialog v-model="showWorkspaceSwitcher" max-width="600px">
            <header class="flex items-center justify-between p-4">
              <h1 class="h1">Workspaces</h1>

              <button
                class="btn btn-icon border-transparent"
                @click="showWorkspaceSwitcher = false"
              >
                <div class="i-ph-x-bold" />
              </button>
            </header>
            <ul class="grid grid-cols-2 gap-4 px-4 pb-5">
              <li
                v-for="(workspaceData, workspaceId) in workspace.userWorkspaces"
              >
                <WorkspaceCard
                  v-if="workspaceData"
                  :active="workspace.workspaceId === workspaceId"
                  :workspace="workspaceData"
                  @done="showWorkspaceSwitcher = false"
                />
              </li>
            </ul>
          </UiDialog>
        </div>
        <AppSplashScreen v-else />
      </Transition>
    </ClientOnly>
  </div>
</template>

<style scoped>
.user-menu-link {
  @apply body-2 hover:bg-surface-bg inline-flex w-full rounded-lg px-4 py-2 text-left;
}
</style>
